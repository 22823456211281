<template>
  <div class="columns is-multiline stadistics" v-if="!loading">
    <!-- <div class="column is-4">
      <div class="box">
        <p class="dashtitle">Total $ (Recompensa)</p>
        <p class="dashAmount">
          <span>{{stadistics.sumProductsPrice}}</span>
        </p>
      </div>
    </div> -->
    <div class="column is-12">
      <p class="subtitle">{{ isDefaultData ? 'Overview' : 'Resumen'}}</p>
    </div>
    <!-- <div class="column is-4">
      <div class="box">
        <p class="dashtitle">Total comprados</p>
        <p class="dashAmount">{{ stadistics.countSellerTransactions ? parseFloat(stadistics.countSellerTransactions).toLocaleString('en', { maximumFractionDigits: 0 }) : 0 }}</p>
      </div>
    </div> -->
    <div class="column is-4">
      <div class="box">
        <p class="dashtitle">Usuarios registrados</p>
        <p class="dashAmount">{{ stadistics.countUsers ? parseFloat(stadistics.countUsers).toLocaleString('en', { maximumFractionDigits: 0 }) : 0 }}</p>
      </div>
    </div>
    <div class="column is-4">
      <div class="box">
        <p class="dashtitle">Celulares registrados</p>
        <p class="dashAmount">{{ stadistics.countProducts ? parseFloat(stadistics.countProducts).toLocaleString('en', { maximumFractionDigits: 0 }) : 0 }}</p>
      </div>
    </div>
    <div class="column is-4">
      <div class="box">
        <p class="dashtitle">Celulares comprados</p>
        <p class="dashAmount">{{ stadistics.countProductTimelinesWithCloseDate ? parseFloat(stadistics.countProductTimelinesWithCloseDate).toLocaleString('en', { maximumFractionDigits: 0 }) : 0 }}</p>
      </div>
    </div>
    <!-- <div class="column is-4">
      <div class="box">
        <p class="dashtitle">Procesos pendientes</p>
        <p class="dashAmount">{{ stadistics.countPendingProductTimelines ? parseFloat(stadistics.countPendingProductTimelines).toLocaleString('en', { maximumFractionDigits: 0 }) : 0 }}</p>
      </div>
    </div> -->
    <!-- <div class="column is-12">
      <p class="subtitle">Transacciones</p>
    </div> -->
    <!-- <div class="column is-4">
      <div class="box">
        <p class="dashtitle">Usuarios nuevos</p>
        <p class="dashAmount">{{ stadistics.countNewUsers ? parseFloat(stadistics.countNewUsers).toLocaleString('en', { maximumFractionDigits: 0 }) : '-' }}</p>
      </div>
    </div>
    <div class="column is-4">
      <div class="box">
        <p class="dashtitle">Conexiones recurrentes</p>
        <p class="dashAmount">{{ stadistics.countRecurrentUsers ? parseFloat(stadistics.countRecurrentUsers).toLocaleString('en', { maximumFractionDigits: 0 }) : '-' }}</p>
      </div>
    </div> -->
    <!-- <div class="column is-4">
      <div class="box">
        <p class="dashtitle">$ (Recompensa) totales (en circulación)</p>
        <p class="dashAmount">{{ stadistics.sumTotalProductPrice ? parseFloat(stadistics.sumGivsUser).toLocaleString('en', { maximumFractionDigits: 0 }) : 0 }}</p>
      </div>
    </div>
    <div class="column is-4">
      <div class="box">
        <p class="dashtitle">$ (Recompensa) retenidos</p>
        <p class="dashAmount">{{ stadistics.sumProductsDetained ? parseFloat(stadistics.sumProductsDetained).toLocaleString('en', { maximumFractionDigits: 0 }) : 0 }}</p>
      </div>
    </div> -->
  </div>
  <div v-else>
    <loading-component />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'Stadistics',
  data () {
    return {
      stadistics: {},
      loading: false,
      isDefaultData: true
    }
  },
  props: {
    dateSegmentation: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    LoadingComponent: () => import('@/components/ui/Loading')
  },
  watch: {
    dateSegmentation (newData, oldData) {
      if (!newData) return this.getDashboardDefaultData()

      this.updateStatistics(newData)
    }
  },
  methods: {
    ...mapActions(['getDashboard', 'getDashboardSegmentation']),
    updateStatistics (newData) {
      this.loading = true
      let dateObj

      if (newData && newData.start && newData.end) {
        // Convierte las fechas a un formato que la API pueda manejar
        dateObj = {
          start: moment(newData.start).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          end: moment(newData.end).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        }
      } else {
        // Carga los datos del dashboard por defecto si no se proporcionó ninguna fecha
        return this.getDashboardDefaultData()
      }

      // Obtener datos del dashboard para el período especificado
      this.getDashboardDataForPeriod(dateObj)
    },
    getDashboardDataForSingleDay (date) {
      this.getDashboardSegmentation(date)
        .then(data => {
          this.stadistics = data
          this.loading = false
        })
        .catch(error => {
          console.error('Error al obtener los datos de segmentación del dashboard para un solo día:', error)
          this.loading = false
        })
    },
    getDashboardDefaultData () {
      this.getDashboard()
        .then(data => {
          this.stadistics = data
          this.$emit('stockData', data?.countUserProductsWithStatus)
          this.loading = false
          this.isDefaultData = true
        })
        .catch(error => {
          console.error('Error al obtener los datos del dashboard:', error)
          this.loading = false
        })
    },
    getDashboardDataForPeriod (date) {
      this.getDashboardSegmentation(date)
        .then(data => {
          this.stadistics = data
          this.loading = false
          this.isDefaultData = false
        })
        .catch(error => {
          console.error('Error al obtener los datos de segmentación del dashboard:', error)
          this.loading = false
        })
    }
  },
  beforeMount () {
    // this.getDashboardDefaultData()
    this.updateStatistics(this.dateSegmentation)
    this.getDashboard()
      .then(data => {
        this.$emit('stockData', data?.countUserProductsWithStatus)
      })
  }
}
</script>

<style scoped>
  .dashtitle {
    font-size: 14px;
    /* min-height: 42px; */
    text-align: center;
  }
  .dashAmount {
    font-size: 28px;
    font-weight: bolder;
    margin-top: 3px;
    text-align: center;
  }
  .stadistics .box:hover {
    background-color: #FAFAFA;
  }
</style>
